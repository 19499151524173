import React from 'react';

const errorStyles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    backgroundColor: '#f8d7da',
    border: '1px solid #f5c6cb',
    borderRadius: '4px',
    padding: '20px',
    margin: '20px',
    maxWidth: '600px',
  },
  heading: {
    color: '#721c24',
    marginTop: 0,
  },
  errorMessage: {
    backgroundColor: '#fff',
    border: '1px solid #eee',
    borderRadius: '4px',
    padding: '10px',
    fontSize: '14px',
    overflow: 'auto',
    maxHeight: '200px',
  },
};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Update state with error details
    this.setState({ error, errorInfo });
    
    // Log the error to the console
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={errorStyles.container}>
          <h1 style={errorStyles.heading}>Something went wrong</h1>
          <p>We apologize for the inconvenience. Here are the error details:</p>
          <div style={errorStyles.errorMessage}>
            <strong>Error: </strong>{this.state.error && this.state.error.toString()}
            <br />
            <strong>Stack Trace: </strong>
            <pre>{this.state.errorInfo && this.state.errorInfo.componentStack}</pre>
          </div>
          <p>Please try refreshing the page or contact support if the problem persists.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;