import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import translationEN from '../locales/en_translation.json';
import translationES from '../locales/es_translation.json';
import translationRU from '../locales/ru_translation.json';
import translationZH from '../locales/zh_translation.json';
import translationptBR from '../locales/ptBR_translation.json';

// The translation resources
const resources = {
  en: { translation: translationEN },
  es: { translation: translationES },
  ru: { translation: translationRU },
  zh: { translation: translationZH },
  ptBR: { translation: translationptBR },
};

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en', // Use 'en' if the current language translations are missing
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
  });

export default i18n;
