import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorBoundary from './components/ErrorBoundary';
import './components/i18n';

// Manually load the App component with error handling
class AppLoader extends Component {
  state = {
    App: null,
  };

  componentDidMount() {
    this.loadApp();
  }

  loadApp = () => {
    import('./App')
      .then((module) => {
        this.setState({ App: module.default });
      })
      .catch((error) => {
        if (/Loading chunk [\d]+ failed/.test(error.message)) {
          console.error('ChunkLoadError caught:', error);
          // Suppress the error and do nothing, or retry silently
          this.loadApp();
        } else {
          console.error('Error loading application:', error);
        }
      });
  };

  render() {
    const { App } = this.state;

    return App ? <App /> : null;  // No loading text, just wait until the App is ready
  }
}

// Service Worker Registration with error handling
try {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      navigator.serviceWorker.register(swUrl)
        .then((registration) => {
          console.log('Service Worker registered successfully:', registration.scope);

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    console.log('New content is available; please refresh.');
                  } else {
                    console.log('Content is cached for offline use.');
                  }
                }
              };
            }
          };
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  } else {
    console.log('Service Workers are not supported in this browser or not in production mode');
  }
} catch (error) {
  console.error('An error occurred during service worker registration:', error);
  // Suppress the error and continue loading the app
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <AppLoader />  {/* No loading text, just load the app */}
  </ErrorBoundary>
);
